<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="600">
            <v-card class="px-4">
                <v-card-title class="mb-3">
                    <span class="text-h4 font-weight-bold">班级课程码</span>
                    <v-spacer></v-spacer>
                    <v-icon size="24" class="btn-close" @click.stop="close">mdi-close</v-icon>
                </v-card-title>
                <div class="share-course-content">
                    <div class="left">
                        <div id="qrCodeDiv" ref="qrCodeDiv" style="margin: 0 auto;"></div>
                    </div>
                    <div class="right">
                        <v-text-field v-model="className" class="mb-3" disabled dense></v-text-field>
                        <v-text-field v-model="topicName" class="mb-0" disabled dense></v-text-field>
                        <div class="row-line">
                            <div class="form-label mr-4">付费课程</div>
                            <v-switch
                                v-model="isCharge"
                                inset
                                dense
                                class="mr-8"
                            ></v-switch>
                            <v-text-field label="课程价格" 
                                class="mr-0"
                                v-model="price"
                                required
                                v-if="isCharge"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="share-content-mask"></div>
                </div>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
    name: "ShareClassDlg",
    data() {
        return {
            dialog: false,

            classId: '',
            className: '',
            topicName: '',
            isCharge: false,
            price: '',
        };
    },
    computed: {
    },
    created() {},
    activated() {
    },
    mounted() {
    },
    watch: {
    },
    methods: {
        openDlg(classData) {
            this.classId = classData.class_id
            this.className = classData.name
            this.topicName = classData.topic_name
            this.isCharge = classData.is_charge
            this.price = classData.price

            this.dialog = true

            let that = this
            this.$nextTick(function () {
                that.makeCode(that.classId)
            })
        },
        makeCode(classId) {
            this.$refs.qrCodeDiv.innerHTML = ''

            new QRCode(this.$refs.qrCodeDiv, {
                text: "https://m.koocoding.com/course/purchase?id=" + classId,
                width: 150,
                height: 150,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.L
            })
        },
        close() {
            this.$emit("close")
            this.dialog = false
        },
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.share-course-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 25px 40px 25px;
    .left {
        flex: 1;
        margin-right: 40px;
    }
    .right {
        flex: 3;
        .row-line {
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .form-label {
            font-size: 14px;
            color: #bbb;
        }
    }
    .course-num {
        font-weight: bold;
        color: #333;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;
        width: 320px;
        .text {
            font-size: 28px;
            letter-spacing: 2px;
        }
        .num {
            flex: 1;
            font-size: 72px;
            letter-spacing: 5px;
        }
    }
    .share-url {
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 320px;
        border: 1px solid #ccc;
        input{
            height: 40px;
            outline-style: none ;
            // border: 1px solid #ccc;
            padding: 8px 12px;
            font-size: 14px;
            flex: 1;
        }
        .btn-reset-url {
            height: 40px;
            padding: 8px 15px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            cursor: pointer;
        }
        .btn-copy-url {
            height: 40px;
            padding: 8px 15px;
            background-color: rgb(22, 132, 252);
            color: #fff;
            cursor: pointer;
            margin-right: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .share-content-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.btn-close {
    cursor: pointer;
}
</style>
