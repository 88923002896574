<template>
    <v-container fluid class="px-8">
        <!-- 班级的基本内容和状态 -->
        <v-card class="course-info mb-12 pa-6" elevation="2">
            <div class="course-desc">
                <div class="desc-col one">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">班级名称：</div>
                        <div class="text">{{ classData.name }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">班级说明：</div>
                        <div class="text">{{ getText(classData.desc, "无") }}</div>
                    </div>
                    <div class="divider"></div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">当前课程：</div>
                        <div class="text">{{ classData.topic_name && classData.topic_name != "" ? classData.topic_name : "未关联" }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">开课状态：</div>
                        <div class="text">{{ getClassStatus(classData.status) }}</div>
                    </div>
                </div>
                <div class="desc-col one">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">开始日期：</div>
                        <div class="text">{{ dateFormat('YYYY/mm/dd', classData.start_date) }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">结束日期：</div>
                        <div class="text">{{ dateFormat('YYYY/mm/dd', classData.end_date) }}</div>
                    </div>
                    <div class="divider"></div>
                    <!-- <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">上课时间：</div>
                        <div class="text">{{ classData.time }}</div>
                    </div> -->
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">付费课程：</div>
                        <div class="text">{{ classData.is_charge ? '是' : '否' }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">课程价格：</div>
                        <div class="text">{{ getPrice(classData.price) }}</div>
                    </div>
                </div>
                <div class="desc-col" style="margin-right: 10px">
                    <v-btn
                        tile
                        dark
                        color="light-green"
                        class="font-weight-bold text-body-1 mr-0 mb-12"
                        @click.stop="openShareClassDlg(classData)"
                    >
                        <v-icon left> mdi-qrcode </v-icon>
                        课程码
                    </v-btn>
                    <v-btn
                        tile
                        dark
                        color="amber darken-1"
                        class="font-weight-bold text-body-1 mr-0"
                        @click.stop="gotoTopic(classData.topic_id)"
                    >
                        <v-icon left> mdi-cube </v-icon>
                        课程包
                    </v-btn>
                </div>
            </div>
        </v-card>

        <!-- 表头 & 按钮 -->
        <div class="add-btn-row">
            <div>
                <div class="list-title mb-3">
                    当前班级 - 学生列表
                </div>
            </div>
            <div>
                <v-btn
                    tile
                    dark
                    color="light-blue darken-1"
                    class="font-weight-bold text-body-1 mr-0"
                    @click.stop="openSelectStudentDlg(classData.class_id)"
                >
                    <v-icon left small> mdi-account-search </v-icon>
                    添加学生
                </v-btn>
            </div>
        </div>
       
        <!-- 当前班级中的学生列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 mb-16"
            :hide-default-footer="datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
        >
            <template v-slot:no-data>
                暂无学生
            </template>
            <template v-slot:item.avatar="{ item }">
                <v-avatar color="white" size="36">
                    <v-img
                        :lazy-src="getAvatar(item.avatar)"
                        :src="getAvatar(item.avatar)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.nickname="{ item }">
                <div class="font-weight-medium">
                    <div class="active-name font-weight-medium" @click.stop="goto(item.class_id)" >
                        {{ item.name }}
                    </div>
                </div>
            </template>
            <template v-slot:item.name="{ item }">
                <div class="font-weight-medium">
                    <div class="active-name" @click.stop="goto(item.class_id)" >
                        {{ getStudentName(item) }}
                    </div>
                </div>
            </template>
            <template v-slot:item.number="{ item }">
                <div>
                    {{ getStudentNumber(item.number) }}
                </div>
            </template>
            <template v-slot:item.phone="{ item }">
                <div>
                    {{ getStudentPhone(item.phone) }}
                </div>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip :color="item.status == 2 ? 'light-green lighten-1' : 'grey lighten-1'" small dark 
                        class="font-weight-medium"
                >
                    {{ getStudentStatus(item.status) }}
                </v-chip>
            </template>
            <template v-slot:item.operate="{ item }">
                <!-- <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="orange"
                            @click.stop="openEditClassDlg(item)"
                        >
                            <v-icon dark> mdi-eye </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑信息</span>
                </v-tooltip> -->
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="red darken-1"
                            @click.stop="openConfirmDlg(item.uid)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>从班级移除</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <ShareClassDlg ref="shareQRCodeDlg" @close="closeShareClassDlg" />

        <ConfirmDlg
            ref="delStudentConfirmDlg"
            title="移除学生"
            text="确认 移除 当前学生么？"
            keyword=" 移除 "
            @confirm="delStudentConfirm"
        />

        <SelectStudentDlg ref="selectStudentDlg" @submit="submitSelectStudent" />

    </v-container>
</template>

<script>
import SelectStudentDlg from '@/views/components/dialog/SelectStudentDlg';
import ShareClassDlg from "@/views/components/dialog/ShareClassDlg";
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';
import {
    getAdminClass,
    getAdminClassStudentList,
    addAdminClassStudent,
    delAdminClassStudent,
} from "@/api/admin";
import { 
    getTypeColor, 
    gotoSection, 
    getTypeName, 
    getSubTypeName, 
    getSectionTypeColor,
    getCourseInfo,
    getStudentStatus
} from '@/utils/common';
import { getText, getAvatar, getTypeFirstLetter, dateFormat } from "@/utils/util";

export default {
    name: "Class",
    data() {
        return {
            classId: "",
            classData: {},

            headers: [
                { text: "头像", value: "avatar", align: "center", sortable: false, width: 60 },
                { text: "昵称", value: "nickname", align: "left", sortable: false, },
                { text: "学生姓名", value: "name",  align: "left", sortable: false, },
                { text: "账号", value: "number",   align: "left", sortable: false },
                { text: "联系方式", value: "phone", align: "center", sortable: false },
                // 学生状态：未付费/未激活/已激活
                // { text: "状态", value: "status",   align: "center", width: 80 },
                { text: "操作", value: "operate",  align: "center", sortable: false, width: 130 },
            ],
            datas: [],
        };
    },
    created() {
    },
    mounted() {
    },
    activated() {
        if (this.$route.query.id) {
            this.classId = this.$route.query.id;
        }
        this.loadData()
    },
    methods: {
        getText,
        getAvatar,
        getTypeColor,
        getTypeFirstLetter,
        gotoSection,
        getTypeName,
        getSubTypeName,
        getSectionTypeColor,
        getCourseInfo,
        dateFormat,
        getStudentStatus,
        getStudentName(item) {
            if (item.real_name != "") {
                return item.real_name
            }
            return item.name
        },
        getStudentNumber(number) {
            if (number == "") {
                return "暂无"
            }
            return number
        },
        getStudentPhone(phone) {
            if (phone == "") {
                return "未填写"
            }
            return phone
        },
        getPrice(price) {
            if (price == 0) {
                return "免费"
            }
            return price
        },
        // 班级状态
        getClassStatus(status) {
            if (status == 0) {
                return "未开课"
            } else if (status == 1) {
                return "已开课"
            }
        },
        openConfirmDlg(studentId) {
            this.$refs.delStudentConfirmDlg.openDlg(studentId);
        },
        delStudentConfirm(studentId) {
            delAdminClassStudent({
                studentId: studentId,
                classId: this.classId
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.datas.length; i++) {
                        if (this.datas[i].uid == studentId) {
                            this.datas.splice(i, 1)
                            break
                        }
                    }

                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "删除成功",
                    });

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "删除失败",
                    });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },

        loadData() {
            // 获取班级信息
            getAdminClass(this.classId).then((response) => {
                // console.log(response.data);
                this.classData = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });

            // 获取当前班级学生列表
            getAdminClassStudentList(this.classId).then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        openShareClassDlg(classData) {
            this.$refs.shareQRCodeDlg.openDlg(classData);
        },
        closeShareClassDlg() {
            // 重新获取当前班级学生列表
            getAdminClassStudentList(this.classId).then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        gotoTopic(id) {
            if (id && id != "") {
                this.$router.push({ path: "/education-mgr/class-list/class/topic", query: { id: id, scope: 'PRIVATE', classId: this.classId } });
            }
        },
      
        // 选择学生对话框
        openSelectStudentDlg(classId) {
            let selected = this.datas;
            this.$refs.selectStudentDlg.openDlg(classId, selected);
        },
        submitSelectStudent(result) {
            let newStudentIdList = []

            for (let i in result.selected) {
                if (!this.checkInclude(result.selected[i].uid)) {
                    this.datas.push(result.selected[i])
                    newStudentIdList.push(result.selected[i].uid)
                }
            }
            if (newStudentIdList.length > 0) {
                addAdminClassStudent({ classId: this.classId, studentIds: newStudentIdList.join(";") })
            }
        },
        checkInclude(id) {
            for (let i in this.datas) {
                if (this.datas[i].uid == id) {
                    return true
                }
            }
            return false
        },
        goto(id) {
            //pass
        }
    },
    components: {
        ShareClassDlg,
        ConfirmDlg,
        SelectStudentDlg
    },
};
</script>

<style lang="scss" scoped>
// 去掉v-card默认的上下的margin
.v-card {
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course-desc {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .desc-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 20px;

            &.one {
                flex: 1;
            }
            &.two {
                flex: 2;
            }
        }
        .desc-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            .desc-name {
                width: 70px;
                min-width: 70px;
            }
        }
        .text {
            text-align: left;
            font-weight: 300;
            font-size: 14px;
        }
        .rating {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .operate {
        margin: 0 20px;
    }
}
.table-left {
    text-align: left;
}
.table-center {
    text-align: center;
}
.td-line {
    text-align: center;
    display: flex;
    justify-content: center;
    height: unset !important;
    .active-name {
        padding: 8px 25px;
        border-radius: 50px;
        // background-color: #efefef;
        background-color: #fff;
        &:hover {
            cursor: pointer;
            background-color: #fff;
            border-radius: 50px;
        }
    }
}
.expendContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.list-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5px;
    padding-left: 12px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    border-left: 5px solid #8E24AA;
}
.list-group-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: #999;
}

.divider {
    width: 100%;
    border-bottom: 1px dashed #ddd;
    margin-bottom: 12px;
}
</style>
